<template>
  <c-box>
    <c-accordion
      v-if="paymentMethods.length > 0"
      :allow-toggle="true"
      :default-index="accordionDefault"
    >
      <c-accordion-item
        v-for="item in paymentMethods"
        :key="item.id"
        background="#FFFFFF"
        box-shadow="2px 2px 10px rgba(0, 0, 0, 0.15)"
        border-radius="12px"
        mb="1rem"
      >
        <c-accordion-header
          v-chakra="{
            ':hover': {
              borderRadius: '12px',
            },
            ':focus': {
              boxShadow: 'none',
            },
          }"
          :py="['10px', '10px']"
          :px="['16px', '24px']"
          @click="handleChangePaymentMethodId_(item)"
        >
          <c-box
            flex="1"
            text-align="left"
          >
            <c-text
              font-family="Roboto"
              :font-size="['16px', '20px']"
              :font-weight="['500', '700']"
              color="#333"
              :mb="['8px', '8px']"
            >
              {{ item.paymentMethodGroupName }}
            </c-text>
            <c-flex
              :gap="['8px', '16px']"
              flex-wrap="wrap"
            >
              <c-image
                v-for="payment in item.paymentMethods"
                :key="payment.id"
                :src="payment.image"
                :h="['35px', '40px']"
                p="8px"
                :border="selectedPayment == payment.id ? '1px solid #00A68C' : '1px solid #C4C4C4'"
                :background="selectedPayment == payment.id ? '#C7F9E3' : 'transparent'"
                border-radius="12px"
              />
            </c-flex>
          </c-box>
          <c-accordion-icon />
        </c-accordion-header>
        <c-accordion-panel pb="4">
          <c-box
            v-if="item.isCreditCard === 1"
            d="flex"
            flex-direction="column"
            gap="6px"
          >
            <c-text>Nomor Kartu</c-text>
            <c-input
              v-model="creditCardNumber"
              v-mask="'#### #### #### ####'"
              rounded="8px"
              w="100%"
              placeholder="Masukkan nomor kartu"
              size="lg"
              type="tel"
            />
            <c-box
              d="flex"
              gap="6px"
            >
              <c-box flex="2">
                <c-text>Tanggal Kadaluarsa</c-text>
                <c-input
                  v-model="creditCardExpiredDate"
                  v-mask="'##/##'"
                  rounded="8px"
                  w="100%"
                  placeholder="MM/YY"
                  size="lg"
                  type="tel"
                />
              </c-box>
              <c-box flex="1">
                <c-text>CVV</c-text>
                <c-input
                  v-model="creditCardCVV"
                  v-mask="'####'"
                  rounded="8px"
                  w="100%"
                  placeholder="CVV"
                  size="lg"
                  type="tel"
                />
              </c-box>
            </c-box>
          </c-box>
          <c-radio-group
            v-else
            v-model="selectedPayment"
            spacing="2"
          >
            <c-radio
              v-for="(payment, index) in item.paymentMethods"
              :key="payment.id"
              :value="payment.id"
              variant-color="green"
              :py="['8px', '16px']"
              :is-full-width="true"
              pos="relative"
              :is-disabled="loading"
            >
              <c-flex
                align-items="center"
                gap="8px"
              >
                <c-box :w="['100px', '150px']">
                  <c-image
                    :src="payment.image"
                    :h="['20px', '30px']"
                  />
                </c-box>
                <c-text
                  font-family="Roboto"
                  :font-size="['14px', '16px']"
                  color="#333"
                >
                  {{ payment.paymentMethodName }}
                </c-text>
              </c-flex>
              <c-box
                w="100%"
                :border-bottom="index + 1 == item.paymentMethods.length ? 'none' : '1px solid #C4C4C4'"
                pos="absolute"
                left="0px"
                bottom="0px"
              />
            </c-radio>
          </c-radio-group>
        </c-accordion-panel>
      </c-accordion-item>
    </c-accordion>
    <SkeletonPaymentMethod v-else />
  </c-box>
</template>

<script>
import SkeletonPaymentMethod from '@/components/skeletons/skeleton-payment-method.vue'
import { mapActions, mapGetters } from 'vuex'
import cardValidator from 'card-validator'

export default {
  name: 'PaymentMethod',
  components: {
    SkeletonPaymentMethod,
  },
  props: ['value', 'loading', 'detailPayment', 'paddingBottom'],
  data() {
    return {
      creditCardNumber: '',
      creditCardExpiredDate: '',
      creditCardCVV: '',

    }
  },
  computed: {
    ...mapGetters({
      paymentMethods: 'checkout/paymentMethods',
    }),
    creditCardNumberAnd() {
      return `${this.detailPayment.additionalInfo.cardNumber}|${this.detailPayment.isCreditCard}`
    },
    checkCreditCardType() {
      if (!this.detailPayment.additionalInfo.cardNumber) return null
      const detail = cardValidator.number(this.detailPayment?.additionalInfo?.cardNumber?.slice(0, 5))
      if (!detail?.card?.type) return null
      // if (!['visa', 'mastercard'].includes(detail.card?.type)) {
      //   return 'mastercard'
      // }
      return detail?.card?.type
    },
    accordionDefault() {
      if (this.paymentMethods.length == 0) {
        return []
      } else {
        return this.value ? this.paymentMethods.findIndex(payment => payment.paymentMethods.length > 0 ? payment.paymentMethods.some(el => el.id == this.value) : []) : []
      }
    },
    selectedPayment: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    // creditCardExpiryDate() {
    //   if (this.detailPayment.additionalInfo.expMonth && this.detailPayment.additionalInfo.expYear) {
    //     return this.detailPayment.additionalInfo.expMonth + '/' + this.detailPayment.additionalInfo.expYear
    //   } else if (this.detailPayment.additionalInfo.expMonth) {
    //     return this.detailPayment.additionalInfo.expMonth
    //   } else if (this.detailPayment.additionalInfo.expYear) {
    //     return this.detailPayment.additionalInfo.expYear
    //   }
    // },
  },
  watch: {
    creditCardNumber(newValue) {
      this.handleChangePaymentMethod_('cardNumber', newValue)
    },
    creditCardExpiredDate(newValue) {
      this.handleChangePaymentMethod_('expiryDate', newValue)
    },
    creditCardCVV(newValue) {
      this.handleChangePaymentMethod_('cardCVV', newValue)
    },
    creditCardNumberAnd() {
      const findPaymentMethodId = this.paymentMethods
        .find(paymentGroup => paymentGroup.isCreditCard === 1)?.paymentMethods
        .find(paymentItem => paymentItem.paymentMethodCode === this.checkCreditCardType)?.id

      if (findPaymentMethodId && this.detailPayment.isCreditCard === 1) {
        this.$emit('handleChangePaymentMethodId', findPaymentMethodId)
      } else {
        this.$emit('handleChangePaymentMethodId', null)
      }
    },
  },
  created() {
    if (this.paymentMethods.length == 0) {
      this.getPaymentMethods()
    }
  },
  methods: {
    ...mapActions({
      getPaymentMethods: 'checkout/getPaymentMethods',
    }),
    handleChangePaymentMethodId_(item) {
      if (item.isCreditCard === 1) {
        this.$emit('handleChangePaymentMethodId', item.paymentMethods[0].id)
      } else {
        this.$emit('handleChangePaymentMethodId', null)
      }
      this.$emit('handleChangePaymentMethod', {
        ...this.detailPayment,
        isCreditCard: item.isCreditCard,
      })
    },
    handleChangePaymentMethod_(type, data) {
      if (type === 'expiryDate') {
        const [expMonth, expYear] = data.split('/')
        this.$emit('handleChangePaymentMethod', {
          ...this.detailPayment,
          additionalInfo: {
            ...this.detailPayment.additionalInfo,
            expMonth,
            expYear,
          },
        })
        return
      }
      this.$emit('handleChangePaymentMethod', {
        ...this.detailPayment,
        additionalInfo: {
          ...this.detailPayment.additionalInfo,
          [type]: data,
        },
      })
    },
  },
}
</script>
