import { render, staticRenderFns } from "./payment-method.vue?vue&type=template&id=9917929a&"
import script from "./payment-method.vue?vue&type=script&lang=js&"
export * from "./payment-method.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CImage: require('@chakra-ui/vue').CImage, CFlex: require('@chakra-ui/vue').CFlex, CBox: require('@chakra-ui/vue').CBox, CAccordionIcon: require('@chakra-ui/vue').CAccordionIcon, CAccordionHeader: require('@chakra-ui/vue').CAccordionHeader, CInput: require('@chakra-ui/vue').CInput, CRadio: require('@chakra-ui/vue').CRadio, CRadioGroup: require('@chakra-ui/vue').CRadioGroup, CAccordionPanel: require('@chakra-ui/vue').CAccordionPanel, CAccordionItem: require('@chakra-ui/vue').CAccordionItem, CAccordion: require('@chakra-ui/vue').CAccordion})
