<template>
  <c-box
    background="#FFFFFF"
    box-shadow="2px 2px 10px rgba(0, 0, 0, 0.15)"
    border-radius="12px"
    mb="1rem"
    w="100%"
    :p="['16px', '24px']"
  >
    <c-box
      :w="['125px', '150px']"
      :h="['24px', '30px']"
      :mb="['8px', '16px']"
    >
      <vue-skeleton-loader
        type="rect"
        width="100%"
        height="100%"
        animation="fade"
      />
    </c-box>
    <c-flex
      :gap="['8px', '16px']"
      flex-wrap="wrap"
    >
      <c-box
        v-for="item in 3"
        :key="item"
        :w="['80px', '100px']"
        :h="['35px', '40px']"
      >
        <vue-skeleton-loader
          type="rect"
          width="100%"
          height="100%"
          rounded
          animation="fade"
        />
      </c-box>
    </c-flex>
  </c-box>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue'  

export default {
  name: 'SkeletonPaymentMethod',
  components: { VueSkeletonLoader },
}
</script>
