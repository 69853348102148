var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "background": "#FFFFFF",
      "box-shadow": "2px 2px 10px rgba(0, 0, 0, 0.15)",
      "border-radius": "12px",
      "mb": "1rem",
      "w": "100%",
      "p": ['16px', '24px']
    }
  }, [_c('c-box', {
    attrs: {
      "w": ['125px', '150px'],
      "h": ['24px', '30px'],
      "mb": ['8px', '16px']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "animation": "fade"
    }
  })], 1), _c('c-flex', {
    attrs: {
      "gap": ['8px', '16px'],
      "flex-wrap": "wrap"
    }
  }, _vm._l(3, function (item) {
    return _c('c-box', {
      key: item,
      attrs: {
        "w": ['80px', '100px'],
        "h": ['35px', '40px']
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "rounded": "",
        "animation": "fade"
      }
    })], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }